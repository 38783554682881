import React from 'react';

import Title from '@components/Title';

interface Props {
  title: string;
  info: string;
  showInfo?: boolean;
}
const Header: React.FC<Props> = ({ title, info, showInfo = true }) => {
  return (
    <>
      <Title>{title}</Title>
      {showInfo && (
        <span className="text-sm" data-testid="join-title-info">
          {info}
        </span>
      )}
    </>
  );
};

export default Header;
