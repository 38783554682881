import { TFunction } from 'i18next';

import {
  getRedirectingFormsOptions,
  getUpdateProgressClubOptions,
} from './optionsList';

import { FormFieldsProps, FormInfoProps, FormValues } from '../types';

export const getFormInformation = (
  t: TFunction,
  formName: string
): FormFieldsProps => {
  const formField: FormInfoProps = {
    contactInformation: {
      firstName: {
        name: 'firstName',
        label: t('join-form.field_first_name_label', 'First name'),
        required: true,
      },
      lastName: {
        name: 'lastName',
        label: t('join-form.field_last_name_label', 'Last name'),
        required: true,
      },
      email: {
        name: 'email',
        label: t('join-form.field_email_label', 'Email'),
        required: true,
      },
      phoneCode: {
        name: 'phone_code',
        label: t('join-form.field_phone_code_label', 'Country code'),
        required: false,
      },
      phoneNumber: {
        name: 'phone_number',
        label: t('join-form.field_phone_number_label', 'Phone'),
        required: false,
      },
      gender: {
        name: 'gender',
        label: t('join-form.field_gender_label', 'Gender'),
        required: false,
      },
      genderDescription: {
        name: 'genderDescription',
        label: '',
        required: false,
      },
      ageCertification: {
        name: 'agecertification',
        label: t(
          'join-form.field_age_certification_label',
          'Age certification'
        ),
        required: true,
        description: t(
          'join-form.field_age_certification_description',
          'I certify that I am currently not under the age of 18.'
        ),
      },
      yearOfBirth: {
        name: 'yearOfBirth',
        label: t('join-form.field_year_of_birth_label', 'Birth year'),
        required: true,
        tooltipText: t(
          'join-form.field_year_of_birth_tooltip_label',
          'Due to Rotary policies we only collect membership requests from candidates 18 and older.'
        ),
        yearsRange: 117,
        acceptedAge: 18,
      },
    },
    clubMeetingPreferences: {
      addressTypeSelect: {
        name: 'type',
        label: t('join-form.address_type_select_label', 'Address type'),
        required: true,
      },
      addressFields: {
        name: 'club_meeting_address_fields',
        formAddress: {
          placeholder: {
            lineTwo: t(
              'join-form.address_fields_line_two_placeholder',
              'Second line optional'
            ),
            lineThree: t(
              'join-form.address_fields_line_three_placeholder',
              'Third line optional'
            ),
          },
          name: {
            lineTwo: 'primaryAddress.lineTwo',
            lineOne: 'primaryAddress.lineOne',
            lineThree: 'primaryAddress.lineThree',
            countryId: 'primaryAddress.countryId',
            city: 'primaryAddress.city',
            stateId: 'primaryAddress.stateId',
            state: 'primaryAddress.state',
            internationalProvince: 'primaryAddress.state',
            hasStates: 'primaryAddress.hasStates',
            postalCode: 'primaryAddress.postalCode',
          },
        },
      },
      interest: {
        name: 'clubMeetingPreferenceInterest',
        label: t(
          'join-form.club_meeting_preference_interest_label',
          'I am interested in an online meeting option, if available.'
        ),
      },
    },
    redirectingForms: {
      redirectingFormName: {
        name: 'redirecting_form_name',
        label: '',
        options: getRedirectingFormsOptions(t),
      },
    },
    candidateDetails: {
      memberId: {
        name: 'memberId',
        label: t('join-form-referral.field_first_name_label', 'Member ID'),
        required: false,
      },
      firstName: {
        name: 'firstName',
        label: t('join-form-referral.field_first_name_label', 'First name'),
        required: false,
      },
      lastName: {
        name: 'lastName',
        label: t('join-form-referral.field_last_name_label', 'Last name'),
        required: false,
      },
      email: {
        name: 'email',
        label: t('join-form-referral.field_email_label', 'Email'),
        required: false,
      },
      address: {
        name: 'address',
        label: t('join-form-referral.field_email_label', 'Address'),
        required: false,
      },
      phoneCode: {
        name: 'phone_code',
        label: t('join-form-referral.field_phone_code_label', 'Country code'),
        required: false,
      },
      phoneNumber: {
        name: 'phone_number',
        label: t('join-form-referral.field_phone_number_label', 'Phone'),
        required: false,
      },
      birthYear: {
        name: 'birth_year',
        label: t('join-form-referral.field_birth_year_label', 'Birth year'),
        required: false,
      },
      profession: {
        name: 'profession',
        label: t(
          'join-form-referral.field_profession_info_label',
          'Profession'
        ),
        required: false,
      },
      reasonToInvolve: {
        name: 'Reason_to_involved',
        label: t(
          'join-form-referral.field_reason_label',
          'Reason to get involved'
        ),
        required: false,
      },
      gender: {
        name: 'gender',
        label: t('join-form-referral.field_gender_label', 'Gender'),
        required: false,
      },
      progress: {
        name: 'update_progress',
        label: t('join-form-referral.field_progress_label', 'Update progress'),
        required: true,
        options: getUpdateProgressClubOptions(t),
      },
      additionalNotes: {
        name: 'additional_notes',
        label: t(
          'join-form-referral.field_additional_comments_label',
          'Additional Notes'
        ),
        required: false,
      },
    },
  };
  return formField[formName];
};

export const getFormInitialValue = (formName: string = '') => {
  const formInitialValue: FormValues = {
    contactInformation: {
      firstName: '',
      lastName: '',
      email: '',
      phone_number: '',
      genderDescription: '',
      agecertification: false,
      yearOfBirth: '',
    },
    clubMeetingPreferences: {
      interestGroupId: '',
      type: '',
      'join-form-interest-group': '',
      'join-form-interest-source': '',
      primaryAddress: {
        lineTwo: '',
        lineOne: '',
        lineThree: '',
        countryId: '',
        city: '',
        stateId: '',
        state: '',
        internationalProvince: '',
        hasStates: '',
        postalCode: '',
      },
      recaptcha: '',
    },
    redirectingForms: {
      redirecting_form_name: '',
    },
  };
  return formInitialValue[formName] || {};
};
