import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import Element from './Element';

export type RadioClasses = {
  radioBtnContainer?: string;
  radioBtnWrapper?: string;
  radioBtnContent?: string;
  radioBtnInput?: string;
  radioBtnLabel?: string;
  radioBtnTitle?: string;
};

export type RadioOption = {
  value: string;
  label: string;
};

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  options: Array<RadioOption>;
  disabled?: boolean;
  classes?: RadioClasses;
  hasMargins?: boolean;
  hasLabelMargin?: boolean;
  hasLabel?: boolean;
}

const RadioField: React.FC<Props> = ({
  options,
  name,
  required,
  disabled,
  classes,
  label = '',
  hasMargins = true,
  hasLabelMargin = true,
  hasLabel = true,
}) => {
  const {
    radioBtnContainer = 'relative',
    radioBtnWrapper = '',
    radioBtnContent = 'mr-auto',
    radioBtnInput = '',
    radioBtnLabel = '',
    radioBtnTitle = '',
  } = classes || {};

  const [field] = useField(name);
  return (
    <Element
      name={name}
      label={label}
      required={required}
      hasMargins={hasMargins}
      hasLabelMargin={hasLabelMargin}
      displayLabelByDefault={hasLabel}
      classes={{
        container: radioBtnWrapper,
        content: radioBtnContainer,
        item: radioBtnTitle,
      }}
    >
      {options.map(({ label, value }) => {
        const optionId = `${name}-${value}`;
        return (
          <div
            key={optionId}
            className={classNames('RadioOption', radioBtnContent)}
          >
            <input
              data-testid={optionId}
              type="radio"
              checked={value === field.value}
              {...field}
              name={name}
              value={value}
              className={classNames(radioBtnInput, 'RadioInput')}
              aria-label={optionId}
              id={optionId}
              disabled={disabled}
            />
            <div className="items-center inline-flex mb-2 text-xs">
              <label
                data-testid={`label-${optionId}`}
                className={classNames('RadioLabel', radioBtnLabel)}
                htmlFor={optionId}
              >
                <span>{label}</span>
              </label>
            </div>
          </div>
        );
      })}
    </Element>
  );
};

export default RadioField;
