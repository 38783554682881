import { TFunction } from 'i18next';

export const getListUpdateProgressForClub = (t: TFunction) => {
  return [
    {
      name: t(
        'candiate-details-update-progress-club.select.option1',
        'Club reviewed inquiry'
      ),
      id: 'candiate-details-update-progress.select.option1',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option2',
        'Club contacted candidate'
      ),
      id: 'candiate-details-update-progress.select.option2',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option3',
        'Club assigned candidate to club officer'
      ),
      id: 'candiate-details-update-progress.select.option3',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option4',
        'Candidate attended club meeting'
      ),
      id: 'candiate-details-update-progress.select.option4',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option5',
        'Club proposed candidate for membership'
      ),
      id: 'candiate-details-update-progress.select.option5',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option6',
        'Club admitted candidate'
      ),
      id: 'candiate-details-update-progress.select.option6',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option7',
        'Club rejected inquiry'
      ),
      id: 'candiate-details-update-progress.select.option7',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option8',
        'Club determined candidate not interested in Rotary'
      ),
      id: 'candiate-details-update-progress.select.option8',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option9',
        'Candidate sent back to district for reassignment'
      ),
      id: 'candiate-details-update-progress.select.option9',
    },
  ];
};

export const getListUpdateProgressForDistrict = (t: TFunction) => {
  return [
    {
      name: t(
        'candiate-details-update-progress-district.select.option1',
        'District reviewed inquiry'
      ),
      id: 'candiate-details-update-progress.select.option1',
    },
    {
      name: t(
        'candiate-details-update-progress-district.select.option2',
        'District contacted candidate'
      ),
      id: 'candiate-details-update-progress.select.option2',
    },
    {
      name: t(
        'candiate-details-update-progress-district.select.option3',
        'District assigned candidate to district officer'
      ),
      id: 'candiate-details-update-progress.select.option3',
    },
    {
      name: t(
        'candiate-details-update-progress-district.select.option4',
        'District assigned candidate to club'
      ),
      id: 'candiate-details-update-progress.select.option4',
    },
    {
      name: t(
        'candiate-details-update-progress-district.select.option5',
        'District assigned candidate to youth program'
      ),
      id: 'candiate-details-update-progress.select.option5',
    },
    {
      name: t(
        'candiate-details-update-progress-club.select.option6',
        'Club admitted candidate'
      ),
      id: 'candiate-details-update-progress.select.option6',
    },
    {
      name: t(
        'candiate-details-update-progress-district.select.option7',
        'District rejected inquiry'
      ),
      id: 'candiate-details-update-progress.select.option7',
    },
    {
      name: t(
        'candiate-details-update-progress-district.select.option6',
        'District determined candidate not interested in club'
      ),
      id: 'candiate-details-update-progress.select.option8',
    },
    {
      name: t(
        'candiate-details-update-progress-district.select.option8',
        'District determined candidate not interested in Rotary'
      ),
      id: 'candiate-details-update-progress.select.option9',
    },
    {
      name: t(
        'candiate-details-update-progress-district.select.option9',
        'District sent back to RI for reassignment'
      ),
      id: 'candiate-details-update-progress.select.option10',
    },
  ];
};
