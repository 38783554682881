import React from 'react';

import { useQuery } from '@apollo/client';

import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import ExistingMemberJoinOptions from '@presenters/web/components/Leads/Forms/ExistingMemberJoinOptions';
import Header from '@presenters/web/components/Leads/JoinForm/Header';

import { localizedNavigate } from '@utils/localized-navigate';

import fetchIndividualMemberships from '@queries/fetchIndividualMembershipsQuery';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import {
  FetchIndividualMembershipsQuery,
  FetchIndividualMembershipsQueryVariables,
} from '@typings/operations';

export const useFetchMemberAccessStatus = (id: string) =>
  useQuery<
    FetchIndividualMembershipsQuery,
    FetchIndividualMembershipsQueryVariables
  >(fetchIndividualMemberships, { variables: { id } });

const ExistingMembers: React.FC = () => {
  const { t } = useTranslation();

  const { user } = useAppConfig();

  const individualId = user?.individualId;
  const { data, loading } = useFetchMemberAccessStatus(individualId || '');

  if (loading) return <Loading />;

  if (data && data?.getMembershipHistory.length === 0) {
    localizedNavigate('/join-form');
  }

  const title = t(
    'redirecting-form.page_title',
    'Refer, rejoin or change clubs'
  );

  const info = t(
    'redirecting-form.page_title_info',
    'Our records show that you are a former or current member of a Rotary club. Tell us how we can help you (select one of the choices below). For questions, <a target="_blank" href="/en/contact">contact us</a>.'
  );

  return (
    <OneColumn>
      <Header showInfo title={title} info={info} />
      <ExistingMemberJoinOptions />
    </OneColumn>
  );
};

export default ExistingMembers;
