import React from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { every, isEmpty } from 'lodash';

import { Button } from '@components/Button';
import RadioField, { RadioOption } from '@components/Formik/RadioField';

import { MemberSearchFormValues } from '@domain/clubs';
import {
  getFormInformation,
  getFormInitialValue,
} from '@domain/leads/join-form/formInfo';
import { REDIRECTING_FORM_NAME } from '@domain/leads/join-form/optionsList';
import { FormFieldsProps, FormValues } from '@domain/leads/types';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

const ExistingMemberJoinOptions: React.FC = () => {
  const { t } = useTranslation();

  const fieldProps: FormFieldsProps = getFormInformation(t, 'redirectingForms');
  const fieldInitialValue: FormValues = getFormInitialValue('redirectingForms');

  const isAllValuesEmpty = (values: FormValues) => every(values, isEmpty);

  const handleSubmit = (
    values: FormValues,
    helpers: FormikHelpers<MemberSearchFormValues>
  ) => {
    const selectedOption = values[fieldProps.redirectingFormName.name];
    if (selectedOption === REDIRECTING_FORM_NAME.REFERRAL) {
      localizedNavigate('/join-form/referral');
    } else if (selectedOption === REDIRECTING_FORM_NAME.REJOIN) {
      localizedNavigate('/join-form/rejoin');
    } else {
      localizedNavigate('/club-search');
    }
    helpers.setSubmitting(false);
  };

  return (
    <Formik initialValues={fieldInitialValue} onSubmit={handleSubmit}>
      {({ values, isSubmitting }) => {
        const isSubmitDisabled = isAllValuesEmpty(values) || isSubmitting;
        return (
          <Form>
            <div className="desktop:flex-row max-w-lg mt-4">
              <RadioField
                name={fieldProps.redirectingFormName.name}
                label={fieldProps.redirectingFormName.label}
                options={
                  fieldProps.redirectingFormName.options as RadioOption[]
                }
              />
              <Button full disabled={isSubmitDisabled} className="mt-4">
                {t('redirecting-form.submit-label', 'Continue')}
              </Button>
              <Button
                type="reset"
                textNormal
                capitalize
                full
                className="mt-6 mb-6 center shadow-none focus:shadow-none"
              >
                {t('redirecting-form.cancel-label', 'CANCEL')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ExistingMemberJoinOptions;
