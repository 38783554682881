import { TFunction } from 'i18next';

import {
  getListUpdateProgressForClub,
  getListUpdateProgressForDistrict,
} from './updateProgressList';

import { ProfessionSource } from './types';

export enum REDIRECTING_FORM_NAME {
  REFERRAL = 'referral',
  REJOIN = 'rejoin',
  VISIT_CLUB = 'visit_club',
}

export const getRedirectingFormsOptions = (t: TFunction) => {
  return [
    {
      value: REDIRECTING_FORM_NAME.REFERRAL,
      label: t(
        'redirecting-form.field_form_name_option_referral',
        'I want to refer a new member (only current members can make a referral)'
      ),
    },
    {
      value: REDIRECTING_FORM_NAME.REJOIN,
      label: t(
        'redirecting-form.field_form_name_option_rejoin',
        'I want to rejoin or change clubs'
      ),
    },
    {
      value: REDIRECTING_FORM_NAME.VISIT_CLUB,
      label: t(
        'redirecting-form.field_form_name_option_visit_club',
        'I want to visit a club or make up a meeting'
      ),
    },
  ];
};

export const getUpdateProgressClubOptions = (t: TFunction) => {
  const options: ProfessionSource[] = getListUpdateProgressForClub(t);
  return options.map(({ name, id }) => ({ label: name, value: id }));
};

export const getUpdateProgressDistrictOptions = (t: TFunction) => {
  const options: ProfessionSource[] = getListUpdateProgressForDistrict(t);
  return options.map(({ name, id }) => ({ label: name, value: id }));
};
