import React from 'react';

import RequireLogin from '@components/Auth/RequireLogin';
import NotFound from '@components/Routing/NotFound';
import ExistingMembers from '@presenters/web/pages/Leads/JoinForm/ExistingMembers';

import { FEATURE_LEADS_JOIN_FORMS, isEnabled } from '@utils/features';

const ExistingMembersPage: React.FC = () => {
  const isLeadJoinFormEnabled = isEnabled(FEATURE_LEADS_JOIN_FORMS);
  if (!isLeadJoinFormEnabled) {
    return <NotFound />;
  }
  return (
    <RequireLogin>
      <ExistingMembers />
    </RequireLogin>
  );
};

export default ExistingMembersPage;
